import { useQueryClient } from '@tanstack/vue-query'

export function useQueryUtilitiesFns() {
  const queryClient = useQueryClient()
  function invalidateAllBillListQueries() {
    queryClient.invalidateQueries({
      predicate: (query) => {
        return query.queryKey && query.queryKey[0] === 'bills'
      },
    })
  }

  function invalidateAllTeamMembersQueries() {
    queryClient.invalidateQueries({
      predicate(query) {
        return query.queryKey && query.queryKey[0] === 'getOrganizationUser'
      },
    })
  }

  return { invalidateAllBillListQueries, invalidateAllTeamMembersQueries }
}
